<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-radio-group v-model="table.params.type" @change="handleTabs">
          <el-radio-button :label="1">举报岗位</el-radio-button>
          <el-radio-button :label="2">举报简历</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params">
          <el-form-item label="" prop="keyword">
            <el-input clearable v-model="table.params.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
          </el-form-item>
        </el-form>
      </div>
      
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="table.params.type === 1 ? field : field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:company_name1="{row}">
          <span>{{row.company_name}}</span>
        </template>
        <template v-slot:company_phone1="{row}">
          <span>{{row.company_phone}}</span>
        </template>
        <template v-slot:name1="{row}">
          <span>{{row.name}}</span>
        </template>
        <template v-slot:phone1="{row}">
          <span>{{row.phone}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" icon="el-icon-view" @click="showDetail(row)">查看</el-button> 
        </template>
      </VTable> 
    </div>

    <detail ref="detail"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'ReportList',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail,
  },
  data() {
    return {
      field: [
        { name: "name", label: "姓名", hidden: false },
        { name: "phone", label: "手机号", hidden: false },
        { name: "job_name", label: "举报岗位", hidden: false },
        { name: "company_name", label: "举报企业", hidden: false },
        { name: "create_time", label: "举报时间", hidden: false },
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      field2: [
        { name: "company_name1", label: "企业名称", hidden: false },
        { name: "company_phone1", label: "登录账号", hidden: false },
        { name: "name1", label: "被举报人", hidden: false },
        { name: "phone1", label: "被举报人手机号", hidden: false },
        { name: "create_time", label: "举报时间", hidden: false },
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          type: 1, // 类型 1:岗位 2:简历
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/report/list',  {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>