var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proposal__wrap"},[_c('div',{staticClass:"section shadow filter__wrap"},[_c('div',[_c('el-radio-group',{on:{"change":_vm.handleTabs},model:{value:(_vm.table.params.type),callback:function ($$v) {_vm.$set(_vm.table.params, "type", $$v)},expression:"table.params.type"}},[_c('el-radio-button',{attrs:{"label":1}},[_vm._v("举报岗位")]),_c('el-radio-button',{attrs:{"label":2}},[_vm._v("举报简历")])],1)],1),_c('div',{staticClass:"flex"},[_c('el-form',{ref:"elFormDom",attrs:{"inline":"","model":_vm.table.params}},[_c('el-form-item',{attrs:{"label":"","prop":"keyword"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入关键字"},model:{value:(_vm.table.params.keyword),callback:function ($$v) {_vm.$set(_vm.table.params, "keyword", $$v)},expression:"table.params.keyword"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.handleSearch}},[_vm._v("查询 ")])],1)],1)],1)]),_c('div',{staticClass:"table__wrap"},[_c('VTable',{attrs:{"has-pagionation":"","title":"","addText":"添加","field":_vm.table.params.type === 1 ? _vm.field : _vm.field2,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"tree-props":{children: 'children', hasChildren: 'has_child'},"hasPagionation":true,"default-expand-all":true,"showOverflowTooltip":true},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"company_name1",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.company_name))])]}},{key:"company_phone1",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.company_phone))])]}},{key:"name1",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}},{key:"phone1",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.phone))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"mini","icon":"el-icon-view"},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v("查看")])]}}])})],1),_c('detail',{ref:"detail"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }